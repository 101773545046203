<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รายการที่ใช้บริการ</h4>
      </div>

     <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="filterData.search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วันที่บริการ"
                  placeholder="ระบุวันที่บริการ"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterData.date"
                no-title
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsDoctor"
              v-model="filterData.doctor"
              item-text="name"
              item-value="name"
              label="หมอ"
              placeholder="เลืือกหมอ"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsStatus"
              v-model="filterData.status"
              item-text="name"
              item-value="name"
              label="สถานะบริการ"
              placeholder="เลืือกสถานะบริการ"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.service_buyer="{ item }">{{item.service_buyer.name}}</template>
      <template v-slot:item.service_customer="{ item }">{{item.service_customer.name}}</template>
      <template v-slot:item.service_date="{ item }">{{moment(item.service_date).add(543, 'year').format('DD/MM/YYYY')}}</template>
      <template v-slot:item.service_doctor="{ item }">{{item.service_doctor.name}}</template>
      <template v-slot:item.service_room="{ item }">{{item.service_room.name}}</template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-3"
          icon
          @click="viewservice(item)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="printservice(item)"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      loaderAdd: false,
      data: [
        {
          _id: '0001',
          no: 1,
          service_course_code: 'BTM013',
          service_name: 'Botox กราม',
          service_bill_no: 'R2020-0001',
          service_buyer: {
            name: 'คุณเอ'
          },
          service_customer: {
            name: 'คุณเอ'
          },
          service_date: moment(),
          service_doctor: {
            name: 'หมอหนึ่ง'
          },
          service_room: {
            name: 'ห้องตรวจ 1'
          },
          service_status: 'ใช้บริการ'
        }, {
          _id: '0001',
          no: 1,
          service_course_code: 'VS0002',
          service_name: 'Pack V Shape',
          service_bill_no: 'R2020-0002',
          service_buyer: {
            name: 'คุณบี'
          },
          service_customer: {
            name: 'คุณบี'
          },
          service_date: moment(),
          service_doctor: {
            name: 'หมอสอง'
          },
          service_room: {
            name: 'ห้องตรวจ 2'
          },
          service_status: 'ใช้บริการ'
        }, {
          _id: '0003',
          no: 3,
          service_course_code: 'VS0001',
          service_name: 'Pack V Shape',
          service_bill_no: 'R2020-0003',
          service_buyer: {
            name: 'คุณบี'
          },
          service_customer: {
            name: 'คุณซี'
          },
          service_date: moment(),
          service_doctor: {
            name: 'หมอหนึ่ง'
          },
          service_room: {
            name: 'ห้องตรวจ 1'
          },
          service_status: 'ใช้บริการ'
        }, {
          _id: '0004',
          no: 4,
          service_course_code: 'BTM014',
          service_name: 'Botox ลดริ้วรอย',
          service_bill_no: 'R2020-0003',
          service_buyer: {
            name: 'คุณพี'
          },
          service_customer: {
            name: 'คุณเอ'
          },
          service_date: moment(),
          service_doctor: {
            name: 'หมอหนึ่ง'
          },
          service_room: {
            name: 'ห้องตรวจ 1'
          },
          service_status: 'ใช้บริการ'
        }
      ],
      itemsDoctor: [{
        _id: '00000',
        name: 'หมอหนึ่ง',
        value: 'หมอหนึ่ง'
      }, {
        _id: '00001',
        name: 'หมอสอง',
        value: 'หมอสอง'
      }],
      itemsStatus: [{
        _id: '00000',
        name: 'รอปรึกษา',
        value: 'waiting for advice'
      }, {
        _id: '00001',
        name: 'รอตรวจ',
        value: 'waiting for examination'
      }, {
        _id: '00002',
        name: 'รอใช้บริการ',
        value: 'waiting for service'
      }, {
        _id: '00003',
        name: 'ตรวจแล้ว',
        value: 'complete'
      }],
      menuSelectDate: false,
      filterData: {
        date: new Date().toISOString().substr(0, 10),
        search: '',
        doctor: '',
        status: ''
      },
      menuFilterDate: false
    }
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    },
    viewservice (item) {
      console.log('view: ', item)
    },
    printservice (item) {
      console.log('print: ', item)
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          value: 'no',
          sortable: false
        }, {
          text: 'รหัสคอร์ส',
          align: 'center',
          value: 'service_course_code',
          sortable: false
        }, {
          text: 'ชื่อคอร์ส',
          align: 'center',
          value: 'service_name',
          sortable: false
        }, {
          text: 'เลขที่ใบเสร็จ',
          align: 'center',
          value: 'service_bill_no',
          sortable: false
        }, {
          text: 'ผู้ซื้อ',
          align: 'center',
          value: 'service_buyer',
          sortable: false
        }, {
          text: 'ผู้ใช้บริการ',
          align: 'center',
          value: 'service_customer',
          sortable: false
        }, {
          text: 'วันที่ใช้บริการ',
          align: 'center',
          value: 'service_date',
          sortable: false
        }, {
          text: 'หมอ',
          align: 'center',
          value: 'service_doctor',
          sortable: false
        }, {
          text: 'ห้อง',
          align: 'center',
          value: 'service_room',
          sortable: false
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'service_status',
          sortable: false
        }, {
          text: 'ตัวเลือก',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedFilterDateFormatted () {
      const self = this
      return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
    }
  }
}
</script>
